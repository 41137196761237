//

import React from "react";
import {
  Container,
  FormWrap,
  FormContent,
  ImageWrapper,
  QRImage,
  Heading,
} from "./PaymentElements";
import QR1 from "../../../media/googlepay.png";
import QR2 from "../../../media/phonepay.jpeg";

const Payment = () => {
  return (
    <Container id="Payment">
      <FormWrap>
        <FormContent>
          <Heading>Payment Methods</Heading>
          <h2>MOHD BABAN</h2>
          <ImageWrapper>
            <QRImage src={QR1} alt="Google Pay QR" />
            <QRImage src={QR2} alt="PhonePay QR" />
          </ImageWrapper>
        </FormContent>
      </FormWrap>
    </Container>
  );
};

export default Payment;
